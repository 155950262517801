import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';

class PagePositionNav {
  constructor() {
    this.controller = new ScrollMagic.Controller();//ScrollMagicのコントローラー
    this.start = document.querySelector(".js-start-cancel-section");
    this.sections = "[data-page-section]";//セクションのdata属性
    this.settings = {
      addClassName: "is-current"//ナビに付与するclass
    };
  }

  init(options) {

    if(document.querySelector(".js-start-cancel-section")) {
      this.setup(options, this.controller);
      this.attachEvent(this.controller);
    }
  }

  //外部から入力された設定をマージ
  setup(options, controller) {
    this.settings = Object.assign(
      {
        addClassName: this.settings.addClassName
      },
      options || {}
    );
    let scene_start = new ScrollMagic.Scene({
      triggerElement: this.start,
      triggerHook: window.innerWidth >= 768 ?"onCenter" : "onLeave",
      offset: 0
    })
      // .addIndicators()
      .addTo(controller);
      scene_start.on("enter", () => {
        document.querySelector(".cancel-sp-indicator").classList.add("is-active");
      });
      scene_start.on("leave", () => {
        document.querySelector(".cancel-sp-indicator").classList.remove("is-active");
      });
  }

  //ScrollMagicを実行
  attachEvent(controller) {
    let sections = document.querySelectorAll(this.sections);

    for (let section of sections) {
      let scene_pagePositionNav = new ScrollMagic.Scene({
        triggerElement: section,
        triggerHook: window.innerWidth >= 768 ?"onCenter" : "onLeave",
        duration: section.clientHeight,//実行範囲を拡張（セクションの高さいっぱいまで）
        offset: 0
      })
        // .addIndicators()
        .addTo(controller);

      //[data-page-section]エリアに入った場合
      scene_pagePositionNav.on("enter", () => {
        let targetElement = scene_pagePositionNav.triggerElement();//現在地のセクションのエレメントを取得
        this.removeNavActiveClass();//classをリセット これはなくてもいいかも
        document.querySelector(`[ data-page-nav = ${targetElement.getAttribute("data-page-section")}]`).classList.add(this.settings.addClassName);//セクションのデータ属性と同じ値を持つナビゲーションにclassを付与
        document.querySelector(`[ data-page-num = ${targetElement.getAttribute("data-page-section")}]`).classList.add(this.settings.addClassName);
        document.querySelector(`[ data-page-label = ${targetElement.getAttribute("data-page-section")}]`).classList.add(this.settings.addClassName);
      });

      //[data-page-section]エリアを抜けたとき
      scene_pagePositionNav.on("leave", () => {
        let targetElement = scene_pagePositionNav.triggerElement();//現在地のセクションのエレメントを取得

        this.removeNavActiveClass();//classをリセット
      });

      //リサイズやスクロールでアップデート
      scene_pagePositionNav.on("update", function(event) {
        this.duration(section.clientHeight);//durationをアップデート
      });
    }
  }

  removeNavActiveClass() {
    let navElements = document.querySelectorAll("[data-page-nav]");
    for (let navElement of navElements) {
      navElement.classList.remove(this.settings.addClassName);
    }
    let numElements = document.querySelectorAll("[data-page-num]");
    for (let numElement of numElements) {
      numElement.classList.remove(this.settings.addClassName);
    }
    let labelElements = document.querySelectorAll("[data-page-label]");
    for (let labelElement of labelElements) {
      labelElement.classList.remove(this.settings.addClassName);
    }
  }
}

const pagePositionNav = new PagePositionNav();
pagePositionNav.init()