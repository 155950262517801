(function ($) {
  "use strict";

  // PC/SP判定
  // スクロールイベント
  // リサイズイベント
  // スムーズスクロール

  /* ここから */

  const breakpoint = 640;
  const mql = window.matchMedia(`screen and (max-width: ${breakpoint}px)`); //、MediaQueryListの生成
  let deviceFlag = mql.matches ? 1 : 0; // 0 : PC ,  1 : SP

  // pagetop
  let timer = null;
  const $pageTop = $("#pagetop");
  $pageTop.hide();

  // スクロールイベント
  $(window).on("scroll touchmove", function () {
    // スクロール中か判定
    if (timer !== false) {
      clearTimeout(timer);
    }

    // スクロール量が100pxを超えたら、200ms後にフェードイン
    timer = setTimeout(function () {
      if ($(this).scrollTop() > 100) {
        $("#pagetop").fadeIn("normal");
      } else {
        $pageTop.fadeOut();
      }
    }, 200);

    const scrollHeight = $(document).height();
    const scrollPosition = $(window).height() + $(window).scrollTop();
    const footHeight = parseInt($("#footer").innerHeight());

    if (scrollHeight - scrollPosition <= footHeight - 20) {
      // 現在の下から位置が、フッターの高さの位置にはいったら(bottom20px分を引いて調整)
      $pageTop.css({
        position: "absolute",
        bottom: footHeight,
      });
    } else {
      $pageTop.css({
        position: "fixed",
        bottom: "20px",
      });
    }
  });

  // リサイズイベント
  const checkBreakPoint = function (mql) {
    deviceFlag = mql.matches ? 1 : 0; // 0 : PC ,  1 : SP
    // → PC
    if (deviceFlag === 0) {
      // console.log('PC');
    } else {
      // →SP
      // console.log('SP');
    }

    deviceFlag = mql.matches;
  };

  // ブレイクポイントの瞬間に発火
  mql.addListener(checkBreakPoint); //MediaQueryListのchangeイベントに登録

  // 初回チェック
  checkBreakPoint(mql);

  !(function () {
    const viewport = document.querySelector('meta[name="viewport"]');
    function switchViewport() {
      const value =
        window.outerWidth > 360
          ? "width=device-width,initial-scale=1"
          : "width=360";
      if (viewport.getAttribute("content") !== value) {
        viewport.setAttribute("content", value);
      }
    }
    addEventListener("resize", switchViewport, false);
    switchViewport();
  })();

  // スムーズスクロール
  // #で始まるアンカーをクリックした場合にスムーススクロール

  $('a[href^="#"]').on("click", function () {
    const speed = 500;
    // アンカーの値取得
    const href = $(this).attr("href");
    // ヘッダーの高さを取得
    const headerHeight = fixedHeader.innerHeight();
    // 移動先を取得
    // const target = $(href == '#' || href == '' ? 'html' : href);
    const target = $(href == "#" ? "html" : href);
    // 移動先を数値で取得

    if(!$(".l-cancel").length) {
      var position = target.offset().top - headerHeight;
    } else {
      var position = target.offset().top;
    }

    // スムーススクロール
    $("body,html").animate(
      {
        scrollTop: position,
      },
      speed,
      "swing"
    );
    return false;
  });

  // ----------
  // ドロワーの開閉（ヘッダー）
  // ----------
  var parallax = $(".js-parallax-scroll");
  var hamburger = $(".js-hamburger");
  var drawer = $(".js-drawer");

  hamburger.on("click", function () {
    if (hamburger.hasClass("is-open")) {
      $("body").css("overflow", "inherit");
      parallax.css("padding-right", "inherit");
      hamburger.removeClass("is-open");
      hamburger.css("margin-right", "inherit");
      drawer.removeClass("is-open");
    } else {
      $("body").css("overflow", "hidden");
      hamburger.addClass("is-open");
      drawer.addClass("is-open");
      if (window.matchMedia("(min-width: 1024px)").matches) {
        parallax.css("padding-right", "17px");
        hamburger.css("margin-right", "17px");
      } else {
        parallax.css("padding-right", "0px");
        hamburger.css("margin-right", "0px");
      }
    }
  });

  // ----------
  // 固定ヘッダーの出現
  // ----------
  var fixedHeader = $(".js-fixed-header");
  var mv = $(".js-mv");

  $(window).on("scroll", function () {
    var mvHeight = mv.innerHeight();
    if ($(this).scrollTop() > mvHeight) {
      fixedHeader.addClass("is-show");
    } else {
      fixedHeader.removeClass("is-show");
    }
  });

  // ----------
  // ドロワーの開閉（固定ヘッダー）
  // ----------
  var fhHamburger = $(".js-fh-hamburger");
  var fhDrawer = $(".js-fh-drawer");

  fhHamburger.on("click", function () {
    if (fhHamburger.hasClass("is-open")) {
      $("body").css("overflow", "inherit");
      parallax.css("padding-right", "inherit");
      fhHamburger.removeClass("is-open");
      fhHamburger.css("margin-right", "inherit");
      fhDrawer.removeClass("is-open");
    } else {
      $("body").css("overflow", "hidden");
      fhHamburger.addClass("is-open");
      fhDrawer.addClass("is-open");
      if (window.matchMedia("(min-width: 1024px)").matches) {
        parallax.css("padding-right", "17px");
        fhHamburger.css("margin-right", "17px");
      } else {
        parallax.css("padding-right", "0px");
        fhHamburger.css("margin-right", "0px");
      }
    }
  });

  // $(window).resize(function() {
  //   if (window.matchMedia('(min-width: 768px)').matches) {
  //     fhHamburger.removeClass('is-open');
  //     fhDrawer.removeClass('is-open');
  //   }
  // });

  // ----------
  // アコーディオンの開閉
  // ----------
  var accordion = $(".js-accordion");

  accordion.on("click", function () {
    $(this).toggleClass("is-open");
    $(this).next(".p-accordion__body").slideToggle(400);
  });

  // ----------
  // ドロワー内に表示されるメニューの絞り込み（FAQページ）
  // ドロワーの開閉（FAQページ）
  // ----------
  var faqDrawerButton = $(".js-faq-button");
  var faqDrawer = $(".js-faq-drawer");
  var faqOverlay = $(".js-faq-overlay");
  var faqDrawerClose = $(".js-faq-drawer-close");
  var faqDrawerList = $(".p-faq-drawer .p-faq-nav__item");

  faqDrawerButton.on("click", function () {
    // ドロワー内に表示されるメニューの絞り込み
    var buttonText = $(this).find(".c-button-deco-left__text").text();
    for (var i = 0; i < faqDrawerList.length; i++) {
      var category = faqDrawerList.eq(i).data("category");
      if (category == buttonText) {
        faqDrawerList.eq(i).show();
      } else {
        faqDrawerList.eq(i).hide();
      }
    }
    // ドロワーの開閉
    $("body").css("overflow", "hidden");
    faqDrawer.addClass("is-open");
    faqOverlay.addClass("is-show");
    if (window.matchMedia("(min-width: 1024px)").matches) {
      parallax.css("padding-right", "17px");
    } else {
      parallax.css("padding-right", "0px");
    }
    return false;
  });

  faqDrawerClose.on("click", function () {
    $("body").css("overflow", "inherit");
    parallax.css("padding-right", "inherit");
    faqDrawer.removeClass("is-open");
    faqOverlay.removeClass("is-show");
  });

  faqOverlay.on("click", function () {
    $("body").css("overflow", "inherit");
    parallax.css("padding-right", "inherit");
    faqDrawer.removeClass("is-open");
    faqOverlay.removeClass("is-show");
  });

  // ----------
  // ドロップダウンメニューの開閉（FAQページ）
  // ----------
  var searchForm = $(".js-search");
  var dropdownList = $(".p-faq-dropdown .p-faq-nav__item");
  var faqDropdownClose = $(".js-faq-dropdown-close");
  var faqDropdown = $(".js-faq-dropdown");

  // 検索フォームの文字入力
  searchForm.on('compositionend keyup', function () {
    // 検索フォームに入力した文字列を取得
    var searchVal = searchForm.val();
    // 検索フォームに何らかの文字列が入力された場合
    if (searchVal != "") {
      // 変数dropdownListを1個ずつ見ていく
      for (var i = 0; i < dropdownList.length; i++) {
        // 変数dropdownListのdata属性の値を取得
        var word = dropdownList.eq(i).data("word");
        // 変数dropdownListのdata属性に登録されている値と検索フォームに入力した文字列が部分一致した場合
        if (word.indexOf(searchVal) > -1) {
          // .faq-nav__listを表示する
          dropdownList.eq(i).show();
        } else {
          // .faq-nav__listを表示する
          dropdownList.eq(i).hide();
        }
      }
      // ドロップダウンメニューを表示
      faqDropdown.addClass("is-open");
    } else {
      // ドロップダウンメニューを非表示
      faqDropdown.removeClass("is-open");
    }
  });

  // ドロップダウンメニューの閉じるボタン
  faqDropdownClose.on("click", function () {
    faqDropdown.removeClass("is-open");
  });

  // ----------
  // 解約ページのバリデーション
  // ----------
  var cancelCheckboxSum = $(
    '.cancel-section .c-input-checkbox input[type="checkbox"]'
  ).length;
  var cancelButton = $(".js-cancel-button");

  cancelButton.on("click", function () {
    var cancelCheckedSum = $(
      '.cancel-section .c-input-checkbox input[type="checkbox"]:checked'
    ).length;
    var cancelChecked = $(
      '.cancel-section .c-input-checkbox input[type="checkbox"]:checked'
    );
    var cancelNotChecked = $(
      '.cancel-section .c-input-checkbox input[type="checkbox"]:not(:checked)'
    );
    if (cancelCheckboxSum !== cancelCheckedSum) {

      console.log(cancelNotChecked);

      cancelChecked.parents(".cancel-section").removeClass("is-not-checked");

      cancelNotChecked.parents(".cancel-section").addClass("is-not-checked");

      const position = $(".js-mv").offset().top;
      
      let speed = 1000;
      $("html, body").animate({ scrollTop: position }, speed, "swing");

      alert("すべての項目にチェックを入れてください")
      return false;
    }
  });

  // ----------
  // 解約ページのサイドナビのカレント表示
  // ----------
  var sideMenuLink = $(".js-side-menu-link");
  sideMenuLink.on("click", function () {
    sideMenuLink.removeClass("is-current");
    $(this).addClass("is-current");
  });

  //---------------------------
  //戻るボタンクリック
  //---------------------------
  $(".wpcf7c-btn-back").click(function () {
    $(".wpcf7-form").removeClass("custom-wpcf7c-confirmed");
  });

  //---------------------------
  //エラーありの場合 戻る
  //---------------------------

  const wpcf7Elm = document.querySelector(".wpcf7");

  if ($("form.wpcf7-form").length) {
    const position = $("form.wpcf7-form").offset().top;
  }

  if (wpcf7Elm) {
    wpcf7Elm.addEventListener(
      "wpcf7invalid",
      function (event) {
        let speed = 1000;
        jQuery("html, body").animate({ scrollTop: position }, speed, "swing");
      },
      false
    );
  }

  //---------------------------
  //メールアドレスコピペ禁止
  //---------------------------
  $("input[type=email]").on("copy cut paste", function (e) {
    e.preventDefault();
  });

  // 送信が成功したら遷移
  // document.addEventListener(
  //   "wpcf7mailsent",
  //   function (event) {
  //     location = "/resident/procedure/cancel/form/thanks/";
  //   },
  //   false
  // );

  //---------------------------
  //キャンセル承認クリック
  //---------------------------

  $(".js-cancel-checkbox input").click(function() {
    if($(this).prop("checked") == false) {
      $(this).parents(".js-cancel-checkbox").removeClass("is-checked");
    } else {
      $(this).parents(".js-cancel-checkbox").addClass("is-checked");
    }
  });

})(jQuery);
